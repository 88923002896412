import React from 'react';

const MdPhone = () => {



  return (
    <div className="md-phone box has-text-white"><h2 className="has-text-white">Call us for free on <a className="tel-number has-text-white" href="tel:0800 066 3122">0800 066 3122</a></h2></div>
  )

}

export default MdPhone;