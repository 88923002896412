import React from 'react'
import Helmet from '../components/helmet'
import Navbar from '../components/navbar'
import SavingBlock from '../components/saving-block'
import FiftyFifty from '../components/fiftyfifty'
import Footer from '../components/footer'
import MdPhone from '../components/md-phone'
import '../components/style.scss';


const Success = () => (
    <div>
        <Helmet />
        <section className="hero imageBg is-fullheight-with-navbar">
            <Navbar />
            <div className="hero-body">
                <div className="container">
                    <div className="is-half banner-text-container success">
                        <h1 className="is-size-5-mobile is-size-3-tablet is-size-3-widescreen">
                            Thanks for contacting us
				</h1>
                    </div>
                    <div className="home-header-cta-bar blue-cta level">
                        <div className="cta-left">
                            <h2 className="is-hidden-mobile is-size-4-tablet is-size-3-widescreen">
                                If your enquiry is urgent, please call us on
                            </h2>
                        </div>
                        <div className="cta-right">
                            <a className="button" href="tel:0800 066 3122">0800 066 3122</a>
                        </div>
                    </div>
                </div>
            </div >
        </section >
        <section className="section">
            <div className="container content">

                <h2 className="subtitle">We will get back to you very soon</h2>
                <p>Thank you for getting in touch.  A member of our team of insolvency experts will call you back as soon as possible.</p>
                <MdPhone />
            </div>

        </section>

        <FiftyFifty />
        <SavingBlock />
        <Footer />
    </div>
)

export default Success